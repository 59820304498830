import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import ProductDetails from "./ProductDetails"
import ProductSlideshow from "./ProductSlideshow"

import colors from "../../styles/colors"
import { Context } from "../../provider"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  max-width: 1440px;
  margin: 0 auto;

  position: relative;

  @media ${breakpoints.tablet} {
    flex-direction: row;

    align-items: center;

    &:after {
      content: "";

      position: absolute;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%);

      height: 1px;
      width: 100vw;

      background: ${colors.creamLight};
    }

    > div {
      height: 100%;
      flex: 1 1 50%;

      padding: 40px;
    }

    > div:last-child {
      border-left: 1px solid ${colors.creamLight};
    }
  }
`

export default function Product({ product }) {
  return (
    <Context.Consumer>
      {({ addToCart }) => (
        <Wrapper>
          <div>
            <ProductDetails
              title={product?.productTitle}
              description={product?.productDescription?.raw}
              ingredients={product?.ingredients?.raw}
              allergens={product?.allergens?.raw}
              price={product.product.variants[0].price}
              onAddToCart={() => addToCart(product.id)}
            />
          </div>
          <div>
            <ProductSlideshow images={product?.productImages} />
          </div>
        </Wrapper>
      )}
    </Context.Consumer>
  )
}
